import React from "react";
import { useHistory } from "react-router-dom";

import footerIcon from "./../img/footerIcon.png";
import footerLogo from "./../img/footerLogo.png";
import contactIcon from "./../img/contactIcon.png";
function Footer() {
  let history = useHistory();
  return (
    <>
      <div className="landinPage">
        <div class="bodyWrapper">
          <footer class="pageFooter">
            <div class="container">
              <div class="row">
                <div class="col-lg-4">
                  <img src={footerLogo} alt="" />
                  <div class="mainText mt-3 mb-3">Have a Question?</div>
                  <a href="https://knowledgebase.getbizviz.com/"
                    target="_blank" class="mainBtn">
                    <img className="chatImg" src={contactIcon} alt="" />
                    <span>contact support</span>
                  </a>
                </div>
                <div class="col-lg-4">
                  <h5 class="subTitle">html generators</h5>
                  <a

                    target="_blank"
                    href="https://www.smartvcard.com"

                    class="mainText">
                    Smart vCard
                  </a>
                  <a
                    target="_blank"
                    href="https://reviewszip.com"
                    class="mainText">
                    Reviews Zip
                  </a>
                </div>
                <div class="col-lg-4">
                  <h5 class="subTitle">legal</h5>
                  <a onClick={() => {
                    history.push("terms-of-use");
                  }} class="mainText">
                    Terms of Use
                  </a>
                  <a onClick={() => {
                    history.push("privacy-policy");
                  }} class="mainText">
                    Privacy Policy
                  </a>
                  <a
                    onClick={() => {
                      history.push("cookie-policy");
                    }}
                    class="mainText">
                    Cookie Policy
                  </a>
                </div>
              </div>
              <div class="copyRight">
                <img className="clickIcon" src={footerIcon} alt="" />
                <div class="mainText">A BizViz Enterprise</div>
                <div class="mainText">© 2023 -  {new Date().getFullYear()} All Rights Reserved </div>
              </div>
            </div>
          </footer>
        </div>
      </div>

    </>
  );
}

export default Footer;
