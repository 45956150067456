import React from "react";
import { useHistory } from "react-router-dom";
import mainLogo from "./../img/mainLogo.png";
import Lottie from "react-lottie-player";
import lottieJson from "./../img/lottie-img.json";

function Header() {
  let history = useHistory();
  return (
    <div className="landinPage">
      <div class="bodyWrapper">
        {/* <!-- hero section --> */}
        <section class="heroSection">
          {/* <!-- navbar --> */}
          <nav class="navbar navbar-expand-lg bg-body-tertiary">
            <div class="container navContainer">
              <a class="navbar-brand"

                onClick={() => {
                  history.push("/");
                }}
              >
                <img src={mainLogo} alt="" />
              </a>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      aria-current="page"
                      onClick={() => {
                        history.push("/login");
                      }}
                    >
                      login
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      onClick={() => {
                        history.push("/sign-up");
                      }}
                    >
                      free sign-up
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div class="heroContent">
            <div class="container heroContainer">
              <div class="row">
                <div class="col-lg-6 my-lg-auto">
                  <h1 class="heroHeading">
                    <span>html</span>
                    <br />
                    Hosting
                  </h1>
                  <p class="mainText">
                    Exclusively for Zip files
                    <br />
                    created at Smart vCard
                    <br />
                    and Reviews Zip.
                  </p>
                </div>
                <div class="col-lg-6 text-start">
                  <Lottie
                    loop
                    animationData={lottieJson}
                    play
                    speed="1"
                    style={{
                      width: "100%",
                      height: "100%",
                      background: "transparent",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    // <header className="Header">
    //   <div className="container">
    //     <div className="row">
    //       <div className="col-lg-8 mx-auto">
    //         <div className="row">
    //           <div className="col-lg-6 col-6">
    //             <a
    //               className="brand"
    //               onClick={() => {
    //                 history.push("/");
    //               }}
    //             >
    //               <img
    //                 style={{ borderRadius: "0%" }}
    //                 src="https://getbizviz.s3.amazonaws.com/ZIPHOST_LOGO_DASHBOARD.png"
    //                 alt=""
    //               />
    //             </a>
    //           </div>
    //           <div className="col-lg-6 col-6 text-end">
    //             <a
    //               className="btn btn-outline-danger"
    //               onClick={() => {
    //                 history.push("/login");
    //               }}
    //             >
    //               LOGIN
    //             </a>
    //             <a
    //               className="btn btn-danger ms-4"
    //               onClick={() => {
    //                 history.push("/sign-up");
    //               }}
    //             >
    //               FREE SIGN-UP
    //             </a>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </header>
  );
}

export default Header;
