import { SIGN_IN, SIGN_OUT } from "../types";

const INITIAL_STATE = {
  user: {},
};
const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        user: action.payload,
      };
    case SIGN_OUT:
      return null;
    default:
      return state;
  }
};
export default authReducer;
